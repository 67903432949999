"use strict";
import { DeviceObserver } from "./device-observer";
import { constants } from "./enums";
const deviceObserver = new DeviceObserver();
deviceObserver.init({
  mobile: 0,
  tablet: constants.VIEWPORT_WIDTH_TABLET,
  desktop: constants.VIEWPORT_WIDTH_DESKTOP,
  "desktop-wide": constants.VIEWPORT_WIDTH_DESKTOP_WIDE,
  "desktop-ultra-wide": constants.VIEWPORT_WIDTH_DESKTOP_ULTRA_WIDE
});
export { deviceObserver };
